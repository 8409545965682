
// icons
import {  } from 'ionicons/icons';

// components
import { IonHeader, IonGrid, IonToolbar, IonButtons, IonButton,
        IonBackButton, IonIcon, IonTitle, } from '@ionic/vue';

import { useI18n } from 'vue-i18n';

export default {
  props: [
    "title",
    "icon",
  ],
  components: { IonHeader, IonGrid, IonToolbar, IonButtons, IonButton,
                IonBackButton, IonIcon, IonTitle, },
  setup() {
    const { t } = useI18n();

    return {
      // icons

      // variables

      // methods
      t,
    }
  }
}
