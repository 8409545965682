

// Vue reactivity
import { computed, ref, reactive, watch, } from "vue";
import { Plugins } from '@capacitor/core';

// icons
import { star, starHalfOutline, starOutline, pencil, heart, heartOutline,
        cartOutline, cardOutline, add, remove, chatbubbleEllipsesOutline,
        shareSocialOutline, location, timerOutline, bagCheckOutline, carOutline, enterOutline, } from "ionicons/icons";

// components
import { IonPage, IonToolbar, IonContent, IonGrid,
        IonRow, IonCol, IonButtons, IonButton, IonSpinner,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent,
        IonIcon, IonAvatar, IonLabel, IonItem, IonChip, IonNote, IonSelect, IonSelectOption,
        IonSegment, IonSegmentButton, IonList,
        loadingController, modalController } from "@ionic/vue";

import PageHeader from '@/components/PageHeader.vue';
import SectionHeader from '@/components/templates/SectionHeader.vue';
import ImageSlides from '@/components/templates/ImageSlides.vue';
import ProductReviewModal from '@/components/templates/ProductReviewModal.vue';
import CartItemModal from '@/components/templates/CartItemModal.vue';
import SwiperCategoryData from '@/components/templates/SwiperCategoryData.vue';

// API services
import ProductService from '@/services/ProductService';
import UserService from '@/services/UserService';

import { utils } from '@/composables/utils';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import clip from "text-clipper";

export default {
  name: "ProductDetailPage",
  components: {
    IonPage, IonToolbar, IonContent, IonGrid,
    IonSpinner, IonRow, IonCol, IonButtons, IonButton,
    IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent,
    IonIcon, IonAvatar, IonLabel, IonItem, IonChip, IonNote, IonSelect, IonSelectOption,
    IonSegment, IonSegmentButton, IonList, ImageSlides,
    PageHeader, SectionHeader, SwiperCategoryData,
  },
  setup() {
    // methods or filters
    const { Share } = Plugins;
    const store = useStore();
    const { t, locale } = useI18n();
    const { formatDate, presentToast, getLocalizedStr, sleep, } = utils();
    const route = useRoute();
    const parentPath = route.params.parentPath; // may be from liked items page
    const currProductId = computed(() => route.params.id);

    // 1. declare state variables (ref to make them reactive) / methods
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loading = computed(() => store.state.loadingProducts);
    const product = computed(() => store.getters.getProductById(currProductId.value));
    const merchant = computed(() => store.getters.getMerchantById(product.value.merchantId));
    const pickupPoints = computed(() => store.getters.getPickupPointsByMerchantId(product.value.merchantId));
    const groupedDeliveryOptions = computed(() => store.getters.getGroupedDeliveryOptionsByMerchantId(product.value.merchantId));

    const maxDescriptionLen = computed(() => store.state.maxDescriptionLen); // threshold for showing read more button
    const showClippedText = reactive({
      productIntro: true,
      orderNotice: true,
    })

    // 品牌相關產品
    const relatedProducts = computed(() => store.getters.getProductsByMerchantId(product.value.merchantId, product.value.id));
    
    // product reviews
    const productOverallRating = ref(0);
    const productReviews = ref<any>([]);
    const loadingProductReviews = ref(true);
    const showSlides = ref(true);

    const deliveryDistrictFilter = ref("");
    const pageTab = ref("產品介紹"); // 呢個變量 for 轉page 最簡單做法可以係 1,2,3,4,5 清晰D既話可以直接用page name (俾電腦睇only)

    watch(groupedDeliveryOptions, (currOptions) => { // triggered only when direct access to product detail page
      deliveryDistrictFilter.value = Object.keys(currOptions)[0];
    });
    watch(currProductId, (currId) => {
      showSlides.value = false;
      setTimeout(() => showSlides.value = true, 3000);
    });
    
    const isHtmlOverTextLenLimit = (htmlString: any) => (htmlString || "").replace(/(<([^>]+)>)/ig, "").length > maxDescriptionLen.value;
    const clipHtmlText = (htmlString: any) => clip(htmlString, maxDescriptionLen.value, { html: true });

    const updateUserLikedItem = async (product: any, action = 'add') => {
      const loading = await loadingController.create({});
      await loading.present();
      if (action == 'add') ProductService.addUserLikedItem(product.id);
      else ProductService.removeUserLikedItem(product.id);
      await sleep(1.5); // 1.5 seconds
      loading.dismiss();
      product.likedByUser = (action == 'add');
      presentToast( t('successUpdateLikedItems'), 2000, 'top' );
    }
    
    const fetchProductReviews = (productId: any) => {
      loadingProductReviews.value = true;
      ProductService.getProductReviews(productId).then(res => {
        loadingProductReviews.value = false;
        productReviews.value = res;
        if (res.length > 0) {
          productOverallRating.value = res.reduce((total: any, next: any) => total + Number(next.rating), 0) / res.length;
        }
      })
    }

    const openCartItemModal = async () => {
      const modal = await modalController.create({
        component: CartItemModal,
        componentProps: { productId: currProductId.value },
      });
      return modal.present();
    };

    const openNewReviewModal = async (product: any) => {
      const modal = await modalController.create({
        component: ProductReviewModal,
        componentProps: { product },
      });
      modal.onDidDismiss().then(({ data }) => {
        if (data.newProductReviewCreated) {
          fetchProductReviews(currProductId.value);
        }
      })
      return modal.present();
    };

    const getOpeningHoursText = (pickupPoint: any) => {
      const combineText = (consecDaysOfWeek: any, openingHours: any) => {
        const len = consecDaysOfWeek.length;
        const sep = locale.value == 'zh' ? '至' : ' - ';
        const daysOfWeekText = len == 1 ? consecDaysOfWeek[0] :
                              `${consecDaysOfWeek[0]}${sep}${consecDaysOfWeek[len-1].replace("星期", "")}`;
        return `${daysOfWeekText} ${openingHours}`;
      }
      const fields = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      const daysOfWeekChi = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      const daysOfWeekEn = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const daysOfWeek = locale.value == 'zh' ? daysOfWeekChi : daysOfWeekEn;
      const openingHoursText = [];
      let consecDaysOfWeek = [], lastOpeningHours = null;
      for (const day of pickupPoint.openingDaysOfWeek.split(" , ")) {
        const openingHours = `${pickupPoint[`${fields[day]}OpeningTime`].replace(/:00$/, "")} - ${pickupPoint[`${fields[day]}ClosingTime`].replace(/:00$/, "")}` ;
        if (lastOpeningHours == null || lastOpeningHours == openingHours) {
          consecDaysOfWeek.push(daysOfWeek[day]);
        } else { // combo broken
          openingHoursText.push( combineText(consecDaysOfWeek, lastOpeningHours) );
          consecDaysOfWeek = [daysOfWeek[day]];
        }
        lastOpeningHours = openingHours;
      }
      if (consecDaysOfWeek.length > 0) {
        openingHoursText.push( combineText(consecDaysOfWeek, lastOpeningHours) );
      }
      return openingHoursText;
    }
    const shareProduct = async (product: any) => {
      const sharingMsg = `${getLocalizedStr(product, 'title', 'titleEn')} | ${window.location.href}`;
      try {
        await Share.share({
          title: sharingMsg,
          url: window.location.href,
          dialogTitle: t('shareProduct'),
        });
      } catch (e) { // browser
        const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(sharingMsg)}`
        //const url = `http://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`
        window.open(url, '', 'width=600,height=400');
      }
    };

    fetchProductReviews(currProductId.value);

    if (userLoggedIn.value && currProductId.value) {
      UserService.addUserBrowsedProduct(currProductId.value); // add to browse history if users log in
      store.commit('addUserBrowsedProduct', currProductId.value);
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t, getLocalizedStr, formatDate, updateUserLikedItem,
      openNewReviewModal, openCartItemModal,
      getOpeningHoursText, shareProduct,

      // icons
      star, starHalfOutline, starOutline, pencil, heart, heartOutline,
      cartOutline, cardOutline, add, remove, chatbubbleEllipsesOutline,
      shareSocialOutline, location, timerOutline, bagCheckOutline, carOutline, enterOutline,

      // variables
      currProductId,
      userLoggedIn, parentPath, loading, product,
      loadingProductReviews, productReviews, productOverallRating,
      locale, deliveryDistrictFilter,
      merchant, pickupPoints, groupedDeliveryOptions,
      relatedProducts, route,
      isHtmlOverTextLenLimit, clipHtmlText, showClippedText, showSlides,

      pageTab, // 記住要return，咁先可以係上面HTML用到
    };
  },
};
