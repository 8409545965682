
// icons
import { chevronForwardOutline, } from 'ionicons/icons';

// components
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonIcon, } from '@ionic/vue';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';

export default {
  props: [
    "post",
    "hideDate",
    "extraRouterParams",
  ],
  components: { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonIcon, },
  setup() {
    const { t } = useI18n();
    const { formatDate } = utils();

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      chevronForwardOutline,

      // methods
      t, formatDate,
    }
  }
}
