
// Vue reactivity
import { ref, defineComponent, watchEffect } from 'vue';

// icons
import { add, close } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonItem, IonLabel, IonIcon,
        IonButtons, IonButton, IonInput, IonTextarea, IonRange,
        modalController, loadingController, } from '@ionic/vue';
import ProductCard from "@/components/templates/ProductCard.vue";

// API services
import ProductService from '@/services/ProductService';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';

export default defineComponent({
  name: 'ProductReviewModal',
  props: ["product"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonInput, IonTextarea, IonRange, ProductCard },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const rating = ref("");
    const content = ref("");

    // methods or filters
    const { t } = useI18n();
    const { presentToast } = utils();

    const closeModal = async (data: any = {}) => {
      await modalController.dismiss(data);
    };
    const createNewProductReview = async (productId: any, rating: any, content: string) => {
      const loading = await loadingController.create({});
      await loading.present();
      const res = await ProductService.addNewProductReview(productId, rating, content);
      loading.dismiss();
      presentToast( t('ProductPage.successPublishProductReview'), 2000 );
      closeModal({ newProductReviewCreated: true });
    };

    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t, createNewProductReview, closeModal,

      // icons
      add, close,

      // variables
      rating, content,
    }
  }
});
